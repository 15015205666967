

























































































import { Component, Vue } from 'vue-property-decorator'
import { Configuration, RedCapital, SelectedCountry, TitleTemplatePublic } from '@/configuration'
import Minimoainvertir from '@/views/howitworks/Minimoainvertir.vue'
import Iconos from "@/components/landingVisa/Iconos.vue"
import Bancos from "@/components/landingVisa/Bancos.vue"
import Cajero from "@/components/landingVisa/Cajero.vue"
import Tarifas from "@/components/landingVisa/Tarifas.vue"

@Component({
	metaInfo: {
    title: 'Tarjeta Prepago',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
	},
		components: {
			Iconos,
			Bancos,
			Cajero,
			Tarifas
	},
	mounted(){
		const script = document.createElement('script');
		script.src = '/js/hotjar.js';
		script.async = true;
		document.head.appendChild(script);
	}
})

export default class Landing extends Vue {}
