<template>
	<div class="bancos">
        <img :src="imagen" :alt="titulo">
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
        props: {
			imagen : String,
            titulo: String
        },
	})
	export default class Bancos extends Vue {}
</script>

<style lang="scss" scoped>
    .bancos{
        display: flex;
        justify-content: center;
        height: 80px;
        border: 2px solid $rc-prim-n1;
        align-items: center;
        border-radius: 12px;
        width: 80%;
        img{
            height: 50px;
        }
    }
</style>
