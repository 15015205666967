<template>
	<table>
        <tr class="table-titulo">
            <th>
                <div class="tarifas titulo-box">Concepto</div>
            </th>
            <th>
                <div class="tarifas titulo-box">Costo con retiro</div>
            </th>
        </tr>
        <tr class="table-contenido">
            <th><div class="tarifas contenido-box"><li>Retiros en cajero</li></div></th>
            <th><div class="tarifas contenido-box"><li><strong>A nivel nacional en toda la red de cajeros Global Net</strong></li></div></th>
        </tr>
        <tr class="table-contenido">
            <th><div class="tarifas contenido-box"><li>Otras redes cajeros VISA</li></div></th>
            <th><div class="tarifas contenido-box"><li><strong>S/.5.90</strong></li></div></th>
        </tr>
        <tr class="table-contenido">
            <th><div class="tarifas contenido-box"><li>Servicio mensual</li></div></th>
            <th><div class="tarifas contenido-box"><li><strong>GRATIS</strong></li></div></th>
        </tr>
        <tr class="table-contenido">
            <th><div class="tarifas contenido-box"><li>Reposición de tarjeta</li></div></th>
            <th><div class="tarifas contenido-box"><li><strong>S/.10.0</strong></li></div></th>
        </tr>
    </table>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	export default class Tarifas extends Vue {}
</script>

<style lang="scss" scoped>
    .table-titulo{
        
    }
    .table-contenido{
        
    }
    
    .tarifas{
        widows: 100%;
        height: 100%;
        border-radius: 12px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        &.contenido-box{
            color: $rc-prim-g2;
            background: #f4f4f4;
            font-size: 16px;
            font-weight: 400;
            min-height: 50px;
            margin-bottom: 12px;
            justify-content: left;
            li::before {
                color: $rc-prim-g2;
            }
        }
        &.titulo-box{
            color: #fff;
            background: $rc-prim-sunny;
            font-size: 30px;
            min-height: 60px;
            margin-bottom: 20px;
            justify-content: center;
        }
    }
    @each $breakpoint in $breakpoints_screen_text{
	    @include breakpoint_media_queries($breakpoint){
            @if $breakpoint == "x-large"{
            }@else if $breakpoint == "large"{
            }@else if $breakpoint == "medium-to-large"{
            }@else if $breakpoint == "small-to-medium"{
            }@else if $breakpoint == "small"{
                .tarifas{
                    &.contenido-box{
                        font-size: 12px;
                    }
                    &.titulo-box{
                        font-size: 18px;
                    }
                }
            }
	    }
    }
</style>
