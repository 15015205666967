<template>
	<div class="cajero rc-shadow">
        <div class="content">
            <p style="color: #3d3d3d"><strong>Pide tu disposición de efectivo desde nuestros cajeros Global Net.</strong> Ubica tus cajeros aquí</p>
            <v-btn href="http://www.globalnet.com.pe/" target="_blank" class="rc-button_prim rc-mod-btn" style="max-width: 160px; text-transform: none;">Ubicar cajero</v-btn>
        </div>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
        props: {
			imagen : String,
            titulo: String
        },
	})
	export default class Cajero extends Vue {}
</script>

<style lang="scss" scoped>
    .cajero{
        background-color: #fff;
        border-radius: 20px;
        padding: 0 20px;
        .content{
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            min-height: 120px;
            height: 100%;
            background-image: url(/images/landingVisa/cajero.png);
            background-size: 116px;
            background-position: bottom left;
        }
    }
    @each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
			}@else if $breakpoint == "large"{
			}@else if $breakpoint == "medium-to-large"{
			}@else if $breakpoint == "small-to-medium"{
			}@else if $breakpoint == "small"{
                 .cajero{
                    .content{
                        flex-flow: column;
                        padding: 30px;
                    }
                }
			}
		}
	}
</style>
