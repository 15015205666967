<template>
	<div class="visa-icono">
        <div class="icono rc-shadow">
            <img :src="imgIcono" :alt="tituloIcono">
        </div>
        <div class="texto">
            <p v-html="textIcono" />
        </div>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
        props: {
			imgIcono : String,
            tituloIcono: String,
            textIcono : String
        },
	})
	export default class Iconos extends Vue {}
</script>

<style lang="scss" scoped>
    .visa-icono{
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        width: 140px;
        height: 140px;
        margin-top: 20px;
        .icono{
            background: #fff;
            width: 80px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            align-self: center;
        }
        .texto{
            height: 40px;
            display: flex;
            justify-content: center;
            p{
                width: 100%;
                height: 40px;
                text-align: center;
                color: $rc-prim-g2;
                font-weight: 600;
            }
        }
    }
</style>
